<template>
	<div>
		<Dialog :dialog="serviceDialog" :dialog-width="dialogWidth">
			<template v-slot:title>Service Schedule for {{ type }}</template>
			<template v-slot:body>
				<v-form
					ref="serviceForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row class="service-dialog">
						<v-col md="4" class="my-auto py-0">
							<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"
								>Expected Start Date</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<DateTimePicker
								hide-details
								:readonly="dateReadOnly"
								:min-date="today"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`expected-start-date-${uniqFieldId}`"
								placeholder="Expected Start Date"
								v-model="service.start_date"
								v-on:change="updateDates()"
							></DateTimePicker>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`expected-end-date-${uniqFieldId}`" class="btx-label mt-3"
								>Expected End Date</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<DateTimePicker
								hide-details
								:readonly="dateReadOnly"
								:min-date="service.start_date"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`expected-end-date-${uniqFieldId}`"
								placeholder="Expected End Date"
								v-model="service.end_date"
							></DateTimePicker>
						</v-col>
						<v-col v-if="false" md="4" class="py-0"> </v-col>
						<v-col v-if="false" md="8" class="py-0">
							<v-checkbox
								dense
								hide-details
								label="Put item into maintenance and make it unavailable at the starting date."
								class="mt-3 mb-0 p-0"
								v-model="service.maintenance"
							></v-checkbox>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
								>Service Performed by</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="service.performed_by"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="performedItems"
							></RadioInput>
						</v-col>
						<template v-if="service.performed_by == 1">
							<v-col md="4" class="my-auto py-0">
								<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3 required">Engineer</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="vendorList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`vendor-${uniqFieldId}`"
									placeholder="Engineer"
									v-model="service.vendor"
									:rules="[vrules.required(service.vendor, 'Engineer')]"
									:class="{
										required: !service.vendor,
									}"
								></AutoCompleteInput>
							</v-col>
						</template>
						<template v-else>
							<v-col md="4" class="my-auto py-0">
								<label :for="`member-${uniqFieldId}`" class="btx-label mt-3 required">Subcon</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="memberList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`member-${uniqFieldId}`"
									placeholder="Subcon"
									v-model="service.member"
									:rules="[vrules.required(service.member, 'Subcon')]"
									:class="{
										required: !service.member,
									}"
								></AutoCompleteInput>
							</v-col>
						</template>
						<v-col md="4" class="my-auto py-0">
							<label for="reason" class="btx-label mt-3 required">Service Type</label>
						</v-col>
						<v-col md="8" class="py-0">
							<SelectInput
								hide-details
								:items="serviceTypeList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`service-type-${uniqFieldId}`"
								placeholder="Service Type"
								v-model="service.service_type"
								:rules="[vrules.required(service.service_type, 'Service Type')]"
								:class="{
									required: !service.service_type,
								}"
							></SelectInput>
						</v-col>
						<v-col md="4" class="py-0">
							<label for="description" class="btx-label mt-3">Remark</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Remark"
								v-model="service.description"
							></TextAreaInput>
						</v-col>
						<template v-if="!has_uuid">
							<v-col md="4" class="py-0">
								<label for="comment" class="btx-label mt-3">Comment</label>
							</v-col>
							<v-col md="8" class="py-0">
								<TextAreaInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="comment"
									placeholder="Comment"
									v-model="service.comment"
								></TextAreaInput>
							</v-col>
							<v-col md="4" class="py-0">
								<label for="attachment" class="btx-label mt-3">Attachments</label>
							</v-col>
							<v-col md="8" class="py-0">
								<FileUpload id="attachment" v-model="service.attachment"></FileUpload>
							</v-col>
						</template>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="serviceUuid"
					class="white--text"
					:loading="serviceButton"
					:disabled="!formValid || serviceButton"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Update Schedule
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="serviceButton"
					:disabled="!formValid || serviceButton"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Schedule
				</v-btn>
				<v-btn depressed tile :disabled="serviceButton" v-on:click="$emit('close', true)">
					Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import SelectInput from "@/view/components/SelectInput";
import RadioInput from "@/view/components/RadioInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import FileUpload from "@/view/components/FileUpload";
import { EventBus } from "@/core/event-bus/event.bus";
import MomentJS from "moment-timezone";
import ObjectPath from "object-path";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default() {
				return new Object({});
			},
		},
		schedule: {
			type: Object,
			default() {
				return new Object({});
			},
		},
	},
	data() {
		return {
			formValid: true,
			has_uuid: false,
			serviceButton: false,
			pageLoading: false,
			serviceTypeList: [
				{
					text: "On Site",
					value: "on-site",
				},
				{
					text: "In Lab",
					value: "in-lab",
				},
			],
			vendorList: [],
			serviceUuid: null,
			memberList: [],
			service: {
				start_date: null,
				end_date: null,
				maintenance: null,
				service_type: "on-site",
				performed_by: 1,
				vendor: 0,
				member: 0,
				description: null,
				comment: null,
			},
			performedItems: [
				{
					label: "Engineer",
					value: 1,
				},
				{
					label: "Subcon",
					value: 2,
				},
			],
			scheduleId: 0,
			dateReadOnly: true,
		};
	},
	components: {
		Dialog,
		RadioInput,
		FileUpload,
		DateTimePicker,
		TextAreaInput,
		SelectInput,
		AutoCompleteInput,
	},
	methods: {
		init() {
			if (this.has_uuid) {
				this.service.start_date = this.serviceArray.expected_start_date;
				this.service.end_date = this.serviceArray.expected_end_date;
				this.service.maintenance = this.serviceArray.type == 1 ? true : false;
				this.service.service_type = this.serviceArray.service_type;
				this.service.performed_by = this.serviceArray.service_by;
				this.service.vendor = this.serviceArray.supplier;
				this.service.member = this.serviceArray.member;
				this.service.description = this.serviceArray.description;
				this.service.comment = this.serviceArray.comment;
				this.serviceUuid = this.serviceArray.uuid;
			}
		},
		initSchedule() {
			this.scheduleId = ObjectPath.get(this.schedule, "id", 0);
			if (this.scheduleId > 0) {
				let start_date = ObjectPath.get(this.schedule, "date");
				let start_time = ObjectPath.get(this.schedule, "start_time");
				let end_time = ObjectPath.get(this.schedule, "end_time");
				this.service.start_date = MomentJS(`${start_date} ${start_time}`).format("YYYY-MM-DD HH:mm");
				this.service.end_date = MomentJS(`${start_date} ${end_time}`).format("YYYY-MM-DD HH:mm");
			}
		},
		updateDates() {
			if (this.has_uuid) {
				return false;
			}
			if (this.scheduleId > 0) {
				return false;
			}
			const start_date = new Date(this.service.start_date);
			const weekday = MomentJS(start_date).add(7, "days");
			this.service.end_date = this.formatDateTimeRaw(weekday);
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			const formData = new FormData();

			formData.append("schedule_id", this.scheduleId);
			formData.append("start_date", this.service.start_date);
			formData.append("end_date", this.service.end_date);
			// formData.append("maintenance", this.service.maintenance);
			formData.append("service_type", this.service.service_type);
			formData.append("performed_by", this.service.performed_by);
			if (this.service.performed_by == 1) {
				formData.append("vendor", this.service.vendor);
				formData.append("member", 0);
			} else {
				formData.append("member", this.service.member);
				formData.append("vendor", 0);
			}
			if (this.service.description) {
				formData.append("description", this.service.description);
			}
			if (this.service.comment) {
				formData.append("comment", this.service.comment);
			}

			if (this.service.attachment && this.service.attachment.length) {
				for (let i = 0; i < this.service.attachment.length; i++) {
					if (this.service.attachment[i].file) {
						formData.append(`attachments[${i}][file]`, this.service.attachment[i].file);
						formData.append(`attachments[${i}][name]`, this.service.attachment[i].name);
						formData.append(`attachments[${i}][suffix]`, this.service.attachment[i].name);
					}
				}
			}

			ApiService.upload(`${this.endpoint}/${this.typeUuid}/service-schedule`, formData)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset Service has been scheduled." },
					]);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			ApiService.put(`asset/service-schedule/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
	},
	mounted() {
		const today = new Date();
		const _this = this;
		_this.has_uuid = ObjectPath.has(_this.serviceArray, "uuid");
		_this.service.start_date = _this.formatDateTimeRaw(today);
		_this.vendorList = _this.localDB("engineers", []);
		_this.memberList = _this.localDB("subcons", []);
		_this.$nextTick(() => {
			_this.updateDates();
			_this.init();
			_this.initSchedule();
			setTimeout(function () {
				_this.dateReadOnly = false;
			}, 200);
		});
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
