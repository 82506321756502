<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md9 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ title }}
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-dark-blue-bg">Service #</th>
						<th class="p-2 light-dark-blue-bg">Service Type / Status</th>
						<th class="p-2 light-dark-blue-bg">Service Performed By</th>
						<th class="p-2 light-dark-blue-bg">Acknowledgement</th>
						<th class="p-2 light-dark-blue-bg">Duration (Expected)</th>
						<th class="p-2 light-dark-blue-bg">Duration (Actual)</th>
						<th class="p-2 light-dark-blue-bg">Created At</th>
					</tr>
				</thead>
				<tbody v-if="results.length">
					<tr v-for="(td, index) in results" :key="index">
						<td class="p-2 border-top-light-grey">
							<Chip :text="td.barcode" color="cyan"></Chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<Chip
									tooltip
									tooltip-text="Service Type"
									small
									:text="td.service_type_formatted"
									:color="td.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
								></Chip>
								<Chip
									tooltip
									tooltip-text="Invoiced"
									small
									v-if="td.is_invoiced == 1"
									class="mt-1"
									text="Invoiced"
									color="orange darken-2"
								></Chip>
								<Chip
									tooltip
									tooltip-text="Status"
									small
									class="mt-1"
									:text="td.service_status_text"
									:color="td.service_status_color"
								></Chip>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex">
								<div class="ml-2">
									<p class="mb-0">
										<Chip
											tooltip
											tooltip-text="Service By"
											small
											:text="td.service_by_formatted"
											color="blue darken-4 white--text"
										></Chip>
									</p>
									<p class="mb-0">
										<span class="bold-600 mr-1">Service By:</span>{{ td.perfomed_by_display_name }}
									</p>
								</div>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex my-1" v-if="td.is_acknowledged == 1">
								<div class="ml-2">
									<p class="mb-0">
										<Chip
											tooltip
											tooltip-text="Acknowledge Status"
											small
											:text="td.acknowledge_status_formatted"
											:color="
												td.acknowledge_status == 1 ? 'green lighten-1 white--text' : 'red lighten-1 white--text'
											"
										></Chip>
									</p>
									<p class="mb-0">
										<span class="bold-600 mr-1">Acknowledge By:</span>{{ td.acknowledge_by_name }}
									</p>
									<p class="mb-0">
										<span class="bold-600 mr-1">Acknowledge At:</span>{{ td.acknowledge_at_formatted }}
									</p>
								</div>
							</div>
							<div v-else class="text--secondary">N/A</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex flex-column">
								<div>
									<span class="bold-600 mr-1">Start:</span
									><ShowValue
										:object="td"
										object-key="expected_start_date_formatted"
										label="Start Date (Expected)"
									></ShowValue>
								</div>
								<div>
									<span class="bold-600 mr-1">End:</span
									><ShowValue
										:object="td"
										object-key="expected_end_date_formatted"
										label="End Date (Expected)"
									></ShowValue>
								</div>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex flex-column" v-if="td.actual_start_date_formatted">
								<div>
									<span class="bold-600 mr-1">Start:</span
									><ShowValue
										:object="td"
										object-key="actual_start_date_formatted"
										label="Start Date (Actual)"
									></ShowValue>
								</div>
								<div>
									<span class="bold-600 mr-1">End:</span
									><ShowValue
										:object="td"
										object-key="actual_end_date_formatted"
										label="End Date (Actual)"
									></ShowValue>
								</div>
							</div>
							<div v-else class="text--secondary">N/A</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<Chip
								tooltip
								tooltip-text="Created By"
								small
								:text="td.created_by"
								color="blue darken-4 white--text"
								class="mb-1"
							></Chip>
							<TableDateTime
								small
								v-if="td.created_at"
								:human-format="td.created_at"
								:system-format="td.added_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="7">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ title }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
// import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import TableDateTime from "@/view/components/TableDateTime";
import ObjectPath from "object-path";

export default {
	props: {
		title: {
			type: String,
			default: null,
		},
		assetUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			serviceLoading: false,
			results: [],
		};
	},
	methods: {
		get_result() {
			this.serviceLoading = true;
			ApiService.query(`asset/${this.assetUuid}/services`)
				.then((output) => {
					this.results = ObjectPath.get(output, "data.tbody", []);
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.serviceLoading = false;
				});
		},
	},
	components: {
		Chip,
		ShowValue,
		TableDateTime,
	},
	mounted() {
		this.get_result();
	},
	beforeDestroy() {},
	computed: {},
};
</script>
